import { Box, Card, Typography } from "@mui/material";
import React from "react";
import "./tokenomics.styles.css";

const Tokenomics = () => {
  return (
    <Card
      sx={{
        background: "linear-gradient(180deg, #0B5B89 0%, #1D709F 100%)",
        width: "100%",
        height: "500px",
        borderRadius: "20px",
      }}
    >
      {" "}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"600"}
          marginTop={1}
          color={"white"}
          textAlign={"center"}
        >
          CHAMPS Tokenomics
        </Typography>
        <Typography variant="caption" color={"white"}>
          The tax is 6% buy and 6% sell
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="piechart">
          <svg viewBox="0 0 100 100" width="90%" height="90%">
            <path
              d="M 70 50 A 20 20 0 1 1 33.819660112501055 38.24429495415054"
              fill="none"
              strokeWidth={40}
              strokeDasharray="75.39822368615503"
              strokeDashoffset={0}
              stroke="#7E77FF"
              style={{
                transition: "stroke-dashoffset 500ms ease-out 0s",
              }}
            >
              <title>Ethereum Reward</title>
            </path>
            <path
              d="M 33.819660112501055 38.24429495415054 A 20 20 0 0 1 43.81966011250105 30.97886967409693"
              fill="none"
              strokeWidth={40}
              strokeDasharray="12.566370614359172"
              strokeDashoffset={0}
              stroke="#67FFD1"
              style={{
                transition: "stroke-dashoffset 500ms ease-out 0s",
              }}
            >
              <title>Ecosystem Fee</title>
            </path>
            <path
              d="M 43.81966011250105 30.97886967409693 A 20 20 0 0 1 66.18033988749895 38.24429495415053"
              fill="none"
              strokeWidth={40}
              strokeDasharray="25.132741228718345"
              strokeDashoffset={0}
              stroke="#FF6099"
              style={{
                transition: "stroke-dashoffset 500ms ease-out 0s",
              }}
            >
              <title>Liquidity Pool</title>
            </path>
          </svg>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginX: 2,
          marginTop: 4,
        }}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              backgroundColor: "#7E77FF",
              width: "34px",
              height: "34px",
              borderRadius: "50%",
            }}
          />
          <Typography color={"white"}>2% ETH</Typography>
        </Box>

        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              backgroundColor: "#67FFD1",
              width: "34px",
              height: "34px",
              borderRadius: "50%",
            }}
          />
          <Typography color={"white"}>2% Marketing</Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box
            sx={{
              backgroundColor: "#FF6099",
              width: "34px",
              height: "34px",
              borderRadius: "50%",
            }}
          />
          <Typography color={"white"}>2% LP</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default Tokenomics;
