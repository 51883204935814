import React from "react";
import "./totalEthRewaed.styles.css";
import { Box, Typography } from "@mui/material";

const TotalEthReward = ({ totalRewardsDistributed }) => {
  return (
    <div className="total-eth-card">
      {/* <img src="/Images/champs-card.png" alt="" className="backgroud-img" /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <h1 className="card-header">Total ETH Rewards</h1>
        <img
          src="/Images/champ-w.png"
          alt=""
          style={{
            width: "67px",
            height: "62px",
          }}
        />
      </div>
      <Box display={"flex"} alignItems={"center"} paddingX={2} gap={1}>
        <img src="/Images/eth-symbol.png" alt="" style={{ width: "34px" }} />
        <Typography fontSize={22}>
          {" "}
          {Number(totalRewardsDistributed).toFixed(2)}
        </Typography>
        <Typography variant="caption" fontSize={18}>
          ETH
        </Typography>
      </Box>
    </div>
  );
};

export default TotalEthReward;
