import { Box, Typography } from "@mui/material";
import React from "react";

const DetailsCard = ({ contractSupply, contractSymbol }) => {
  return (
    <Box
      sx={{
        background: "#0F5176",
        width: "100%",
        height: "300px",
        borderRadius: "20px",
        marginTop: 2,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Total Supply
        </Typography>
        <Typography> 2.00 Billion</Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Market Cap
        </Typography>
        <Typography>
          {" "}
          {contractSupply
            ? Number(contractSupply / Math.pow(10, 18)).toFixed(2)
            : "0.00"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Typography fontWeight={600} fontSize={"14px"}>
          Reward Holders
        </Typography>
        <Typography>{contractSymbol ? contractSymbol : 0}</Typography>
      </Box>

      <Box display={"flex"} flexDirection={"column"} padding={2}>
        {" "}
        <Typography fontWeight={600} fontSize={"14px"}>
          Website
        </Typography>
        <Typography variant="caption">
          https://www.champscoin.com/XfgJ4aY3Qq2jG8
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} paddingX={2}>
        {" "}
        <Typography fontWeight={600} fontSize={"14px"}>
          Chart
        </Typography>
        <Typography variant="caption">
          https://dex.guru/token/IAUODJFAODF78A98F7A9FDJKSFKA
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailsCard;
