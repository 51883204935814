import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";

const SearchCard = ({ onChange, onClick }) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #0B5B89 0%, #1D709F 100%)",
        width: "100%",
        height: "180px",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          paddingX: 2,
          paddingY: 1,
          gap: 1.5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" fontWeight={"600"} marginTop={1}>
          Search Wallet Details
        </Typography>
        <TextField
          onChange={onChange}
          sx={{
            "& fieldset": { border: "none" },
            backgroundColor: "#0F5176",
            borderRadius: "8px",
            width: "100%",
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          inputProps={{
            style: {
              color: "#FFFFFF",
            },
          }}
          // sx={{
          //   width: "100%",
          //   border: "none",
          //   height: "50px",
          //   background: "#0F5176",
          //   borderRadius: "9px",
          // }}
          placeholder="Input Your Wallet Address"
        />
        <Button
          onClick={onClick}
          sx={{
            width: "100%",
            borderRadius: "37px",
            background:
              "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)",
            color: "white",
            paddingY: 1,
            border: 1,
            borderColor: "white",
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SearchCard;
