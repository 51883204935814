import React from "react";
import "./claimEth.style.css";
import { Box, Button, Typography, alpha } from "@mui/material";
const ClaimEthCard = ({ onClick, claimrewards }) => {
  return (
    <div className="claim-eth-card">
      {/* <img src="/Images/champs-card.png" alt="" className="backgroud-img" /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <h1 className="card-header">Claimable ETH Rewards</h1>
        <img
          src="/Images/champ-w.png"
          alt=""
          style={{
            width: "67px",
            height: "62px",
          }}
        />
      </div>
      <Box display={"flex"} alignItems={"center"} paddingX={2} gap={1}>
        <img src="/Images/eth-symbol.png" alt="" style={{ width: "34px" }} />
        <Typography fontSize={22}>
          {Number(claimrewards / Math.pow(10, 18)).toFixed(2)}
        </Typography>
        <Typography variant="caption" fontSize={18}>
          ETH
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          margin: 2,
          backgroundColor: "white",
          borderRadius: "5px",
          color: "#2B8148",
          marginTop: 2,
          paddingX: 4,
          "&:hover": {
            backgroundColor: alpha("#ffff", 0.8),
          },
        }}
        onClick={onClick}
      >
        Claim
      </Button>
    </div>
  );
};

export default ClaimEthCard;
