import { Box, Card, Typography } from "@mui/material";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import camlotABI from "../../camlotABI.json";

const YourBalance = ({
  ethTokenBalance,
  balance,
  claimrewards,
  currentAdd,
  totalRewardsDistributed,
}) => {
  const [wethToUsdcAmount, setWethToUsdcAmount] = useState();
  const [loading, setLoading] = useState(false);

  const ARBITRUM_MAINNET_JSON_RPC_URL = "https://arbitrum.llamarpc.com";
  const CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET =
    "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
  const WETH_ARBITRUM_MAINNET = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  const USDCe_ARBITRUM_MAINNET = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";

  const convertWethToUSDC = async (amount) => {
    // setLoading(true);
    try {
      const path = [WETH_ARBITRUM_MAINNET, USDCe_ARBITRUM_MAINNET];
      const provider = new ethers.providers.JsonRpcProvider(
        ARBITRUM_MAINNET_JSON_RPC_URL
      );
      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        camlotABI,
        provider
      );

      const tokenAmountt = ethers.utils.parseUnits(amount ? amount : "0", 18);
      await camelotRouter.functions
        .getAmountsOut(tokenAmountt, path)
        .then((amounts) => {
          const [wethIn, usdcOut] = amounts[0];
          console.log(wethIn);
          console.log(
            "Amount of USDCe from WETH: ",
            ethers.utils.formatUnits(usdcOut, 6)
          );
          setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 6));
        })
        .finally(() => setLoading(false))
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    convertWethToUSDC(ethTokenBalance.toString());
  }, [ethTokenBalance]);

  console.log(wethToUsdcAmount, "wethToUsdcAmount");
  return (
    <Card
      sx={{
        background: "linear-gradient(180deg, #0B5B89 0%, #1D709F 100%)",
        width: "100%",
        height: "500px",
        borderRadius: "20px",
        marginBottom: 2,
      }}
    >
      <Typography variant="h5" fontWeight={"600"} color={"white"} margin={2}>
        Your Balance
      </Typography>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#0F5176",
            height: "420px",
            width: "95%",
            borderRadius: "15px",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={2}
            paddingY={2}
            color={"white"}
          >
            {" "}
            <Typography fontWeight={600} fontSize={"14px"} marginTop={2}>
              Your ETH Balance
            </Typography>
            <Typography variant="caption">
              {balance
                ? Number(ethTokenBalance / Math.pow(10, 18)).toFixed(2)
                : "0.00"}
            </Typography>
          </Box>

          {/*  */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={2}
            paddingY={2}
            color={"white"}
          >
            {" "}
            <Typography fontWeight={600} fontSize={"14px"}>
              Claimable ETH Rewards
            </Typography>
            <Typography variant="caption">
              {Number(claimrewards / Math.pow(10, 18)).toFixed(2)}
            </Typography>
          </Box>
          {/*  */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={2}
            paddingY={2}
            color={"white"}
          >
            {" "}
            <Typography fontWeight={600} fontSize={"14px"}>
              Value
            </Typography>
            <Typography variant="caption">
              ${loading ? "0.00" : wethToUsdcAmount}
            </Typography>
          </Box>
          {/*  */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={2}
            paddingY={2}
            color={"white"}
          >
            {" "}
            <Typography fontWeight={600} fontSize={"14px"}>
              Wallet Address
            </Typography>
            <Typography variant="caption">
              {currentAdd ? currentAdd : "0X"}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            paddingX={2}
            paddingY={2}
            color={"white"}
          >
            {" "}
            <Typography fontWeight={600} fontSize={"14px"}>
              Total Distributed CHAMPS Rewards
            </Typography>
            <Typography variant="caption">
              {" "}
              ${" "}
              {/* {Number(totalRewardsDistributed / Math.pow(10, 18)).toFixed(2)} */}
              {Number(totalRewardsDistributed)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default YourBalance;
