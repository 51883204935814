import React from "react";
import "./header.styles.css";
import { Typography } from "@mui/material";

const Header = ({ connectWallet, walletConnected }) => {
  return (
    <div className="header-container">
      <div>
        <img className="img" src={"/Images/chmaps-logo.png"} alt="logo" />
      </div>
      <Typography
        variant="caption"
        sx={{
          fontSize: { xs: "6px", sm: "16px" },
        }}
      >
        Overview
      </Typography>
      <div>
        <button onClick={connectWallet} className="connect-wallet-button">
          {" "}
          {walletConnected ? "Disconnect wallet " : "Connect wallet"}
        </button>
      </div>
    </div>
  );
};

export default Header;
